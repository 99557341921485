exports.components = {
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-case-studies-tsx": () => import("./../../../src/templates/case-studies.tsx" /* webpackChunkName: "component---src-templates-case-studies-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-cms-admin-index-tsx": () => import("./../../../src/templates/cms-admin/index.tsx" /* webpackChunkName: "component---src-templates-cms-admin-index-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/product-details.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-software-details-tsx": () => import("./../../../src/templates/software-details.tsx" /* webpackChunkName: "component---src-templates-software-details-tsx" */),
  "component---src-templates-software-tsx": () => import("./../../../src/templates/software.tsx" /* webpackChunkName: "component---src-templates-software-tsx" */)
}

